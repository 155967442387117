
.image-preview {
  max-width: 100%;
  max-height: 80px;
  overflow: hidden;
  border: 2px solid #ccc;
  border-radius: 10px;
  position: relative;

  &:hover {
    opacity: 0.6;
  }

  .delete {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .alert-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .warning {
    position: absolute;
    bottom: 0px;
    width: 100%;
    font-size: 60%;
    text-align: center;
    color: red;
    background: rgba(255, 255, 255, 0.8);
  }
}
